.not-found-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f2f2f2;
  }
  
  .not-found {
    text-align: center;
    padding: 2rem;
    background-color: #fff;
    border-radius: 0.5rem;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  
  .not-found-title {
    font-size: 4rem;
    margin: 0;
    color: #4f4f4f;
  }
  
  .not-found-subtitle {
    font-size: 2rem;
    margin: 1rem 0;
    color: #9b9b9b;
  }
  
  .not-found-description {
    font-size: 1.2rem;
    line-height: 1.6;
    color: #4f4f4f;
    margin-bottom: 2rem;
  }
  
  .not-found-button {
    display: inline-block;
    padding: 0.8rem 2rem;
    border: none;
    background-color: #4f4f4f;
    color: #fff;
    text-decoration: none;
    border-radius: 0.5rem;
    font-size: 1.2rem;
  }
  